<template>
  <div
    id="app"
    class="app"
    :class="{
      'full-web': !isIntegration,
    }"
    :style="getBgImage"
  >
    <router-view></router-view>
    <LandscapeNotification />
  </div>
</template>

<script>
import { webViewIntegration } from './utility';
import { each, isArray, startCase, has } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import store from './store/index';
import betHandler from '../src/utility/betHandler';
import applyPalette from '@nsftx/games-sdk-js/src/utility/applyPalette';
import gtag from '@nsftx/games-sdk-js/src/utility/gtag';
import eventBus from '@nsftx/games-sdk-js/src/utility/eventBus';
import LandscapeNotification from '@nsftx/games-sdk-js/src/components/LandscapeNotification';
import { BusService } from '@nsftx/games-bus';
import NoSleep from 'nosleep.js';

export default {
  name: 'App',
  components: {
    LandscapeNotification,
  },
  data() {
    return {
      isLoggedIn: false,
      check: null,
      checkTimeout: 10000,
      ticketCanceled: false,
      canceledTicket: {},
      initialChannels: [],
      subChannels: {},
    };
  },
  computed: {
    ...mapGetters([
      'config',
      'user/user',
      'translations',
      'isIsonis',
      'drawnBalls',
      'payinDisabled',
      'odds',
      'colorsDrawn',
      'payinDisabledFromSocket',
      'lastSelectedBet',
      'activeBettingTab',
      'isBettingDisabled',
      'isMobile',
      'isTablet',
    ]),
    ...mapGetters('gamesBetslip', [
      'ticket',
      'activeComponent',
      'totalPayment',
      'futureRounds',
      'selectedBet',
      'isPayinButtonDisabled',
    ]),
    ...mapGetters({
      isUserLoggedIn: 'user/isLoggedIn',
    }),
    isMob() {
      return this.isMobile || window.innerWidth <= 660;
    },
    userProfile() {
      return this['user/user'];
    },
    getBgImage() {
      if (this.isTablet) {
        return this.config.ui.dark ? this.bgImage : this.bgImageLight;
      }
      if (this.isMob) {
        return { backgroundColor: 'var(--background-1)' };
      }
      return this.config.ui.dark ? this.bgImage : this.bgImageLight;
    },
    bgImage() {
      return {
        // eslint-disable-next-line
        backgroundImage: `linear-gradient(to bottom, rgba(26, 28, 29, 0), #1A1C1D 88%), url(${require('@/assets/image.png')})`,
      };
    },
    bgImageLight() {
      return {
        // eslint-disable-next-line
        //  backgroundImage: `linear-gradient(to bottom, rgba(26, 28, 29, 0), #FFFFFF 88%), url(${require('@/assets/' + this.config.productName + '_light_bg.jpg')})`,
        // eslint-disable-next-line
        backgroundImage: `linear-gradient(to bottom, rgba(26, 28, 29, 0), #FFFFFF 88%), url(${require('@/assets/image-light.jpg')})`,
      };
    },
    isStandAlone() {
      return this.config.ui.config.mode === 'standalone';
    },
    isGravityGateway() {
      return this.config.messageProtocol.name === 'gravityGateway';
    },
    isIntegration() {
      if (
        this.config.ui.config.mode &&
        (this.config.ui.config.mode === 'integration' ||
          this.config.ui.config.mode === 'standalone')
      ) {
        return true;
      }
      return false;
    },
    isAndroidPlatform() {
      return webViewIntegration.isActive() && has(window, 'WebAppListener');
    },
  },
  watch: {
    odds(val) {
      if (this.selectedBet) {
        const newBet = JSON.parse(JSON.stringify(this.lastSelectedBet));
        newBet.betTab = this.lastSelectedBet.betTab;
        if (this.selectedBet.type === 'special') {
          //  newBet.odds = val[this.selectedBet.oddIndex][this.selectedBet.oddPosition];
          newBet.odd = val[this.selectedBet.oddIndex][this.selectedBet.oddPosition];
          setTimeout(() => {
            this.setLastSelectedBet(newBet);
            betHandler.addToBetslip(newBet);
            //  this.updateSelectedBet(newBet);
          }, 0);
        } else {
          //  newBet.odds = val[this.selectedBet.betType];
          newBet.odd = val[this.selectedBet.betType];
          setTimeout(() => {
            this.setLastSelectedBet(newBet);
            betHandler.addToBetslip(newBet);
            //  this.updateSelectedBet(newBet);
          }, 0);
        }
      }
    },
    colorsDrawn: {
      handler(val) {
        if (this.selectedBet && this.selectedBet.oddIndex === 9) {
          for (let i = 0; i < 6; i++) {
            if (val[i] === 8 && this.selectedBet.oddPosition === i + 1) {
              this.clearBetslip();
              eventBus.$emit('ClearSpecialBets');
              break;
            }
          }
        }
      },
      deep: true,
    },
    drawnBalls: {
      handler(val) {
        if (this.selectedBet) {
          const drawnBall = val[val.length - 1];
          if (this.selectedBet.numbers.includes(drawnBall)) {
            this.resetBalls();
            this.setLastSelectedBet(false);
            //  this.clearBetslip();
            (async () => {
              await this.clearBetslip();
            })();
          }
        }
        if (val.length === 35) {
          eventBus.$emit('ClearSpecialBets');
          this.clearBetslip();
        }
        if (val.length % 2 === 0) {
          this.setHideLoader(true);
          if (this.isLoggedIn) {
            setTimeout(() => {
              this.getLastTickets();
            }, 300);
          }
          setTimeout(() => {
            this.setHideLoader(false);
          }, 1200);
        }
      },
      deep: true,
    },
    payinDisabledFromSocket(val) {
      if (this.selectedBet && !val) {
        setTimeout(() => {
          if (this.lastSelectedBet !== false) {
            this.setIsPayinButtonDisabled(false);
          }
        }, 1200);
      }
      if (val === true) {
        this.setIsPayinButtonDisabled(true);
      }
    },
    selectedBet: {
      handler(val) {
        if (val && this.lastSelectedBet && !this.payinDisabledFromSocket) {
          this.setIsPayinButtonDisabled(false);
        }
      },
      deep: true,
    },
    isLoggedIn(value) {
      if (value) {
        this.getLastTickets();
      }
    },
  },
  beforeMount() {
    this.busService = new BusService({
      environment: this.config.environment,
      platform: this.config.platformName,
      productName: this.config.productName,
      productId: this.config.productId,
      tenantId: this.config.tenantId,
      locale: this.config.locale,
      applicationName: this.config.applicationName,
      useCommonEventName: true,
      adapters: [{ name: 'GcmAdapter' }],
      clientMessageProtocol: this.config.messageProtocol,
      subChannels: this.subChannels,
      initialChannels: this.initialChannels,
    });
    this.busService.start();
    if (this.isMobile || this.isTablet) {
      this.addBettingTab({
        componentName: 'specials',
        text: 'general_active_tickets',
        active: false,
        disabled: false,
      });
    }
    this.getStats();
    this.getResults();
  },
  mounted() {
    if (this.isMobile) {
      const noSleep = new NoSleep();
      let wakeLockEnabled = false;
      document.addEventListener(
        'click',
        function enableNoSleep() {
          document.removeEventListener('click', enableNoSleep, false);
          if (!wakeLockEnabled) {
            noSleep.enable();
            wakeLockEnabled = true;
          } else {
            noSleep.disable();
            wakeLockEnabled = false;
          }
        },
        false,
      );
    }
    this.load();
    const device = this.isMobile ? 'mobile' : this.isTablet ? 'tablet' : 'desktop';
    this.setupGtag(device);
    this.setMaxBallsInNextSix(this.config.bets);
    this.setMaxBallsNextBall(this.config.bets);
    this.setTheme(this.config);
    this.setPageTitle();
    window.addEventListener('Bus:Message', (event) => {
      const { eventName } = event.detail;
      switch (eventName) {
        case 'SetState':
          this.reset();
          //  store.state.drawnBalls = [];
          if (event.detail.balls) {
            if (event.detail.balls.length >= 30) {
              eventBus.$emit('SwitchToNextBallTab');
            }
            for (let i = 0; i < event.detail.balls.length; i += 1) {
              store.state.drawnBalls.push(event.detail.balls[i].ball);
              this.setDrawnColor(event.detail.balls[i].ball);
              this.updateRemainingBalls(event.detail.balls[i].ball);
            }
          }
          store.state.odds = event.detail.odds || {};
          this.updateRound(event.detail.eventId);
          this.drawnBallsCheck(event.detail.balls);
          store.dispatch('setAppLoaded', true);
          if (store.state.balls.length > 2) {
            this.disableAllBetting(false);
          }
          if (store.state.drawnBalls.length >= 35) {
            this.setLastSelectedBet(false);
            this.clearBetslip();
            this.resetBalls();
            this.disableAllBetting(true);
            eventBus.$emit('ClearSpecialBets');
            this.reset();
          }
          break;
        case 'PayinDisable':
          this.setPayinDisabledFromSocket(true);
          break;
        case 'DrawResult':
          this.setDrawnBallsStateActive(true);
          this.updateRound(event.detail.eventId);
          this.setDrawnColor(event.detail.ball);
          setTimeout(() => {
            this.setDrawnBallsStateActive(false);
          }, 1800);
          // eslint-disable-next-line no-case-declarations
          const ticket = this.ticket.length ? this.ticket : [this.selectedBet];
          if (ticket.length > 0 && ticket.type === 'numbers') {
            betHandler.addToBetslip({
              type: 'numbers',
              numbers: ticket.numbers,
              odd: ticket.odd,
              betTab: ticket.bettingTab,
            });
          }
          setTimeout(async () => {
            if (ticket.bets && ticket.bets[0]) {
              if (ticket.bets[0].isNumberOnBetslip(event.detail.ball)) {
                await store.dispatch('emptyBetslip');
                await store.dispatch('resetBalls');
              }
            }

            store.state.drawnBalls.push(event.detail.ball);
            this.updateRemainingBalls(event.detail.ball);
            this.drawnBallsCheck(store.state.drawnBalls);

            if (this.isBettingDisabled) {
              if (store.state.drawnBalls.length >= 30) {
                eventBus.$emit('SwitchToNextBallTab');
              }
              this.disableAllBetting(false);
            }
          }, 1200);
          break;
        case 'SetResults':
          this.disableAllBetting(true);
          eventBus.$emit('ClearSpecialBets');
          store.state.drawnBalls = [];
          this.resetBalls();
          this.setLastSelectedBet(false);
          this.clearBetslip();
          this.reset();
          break;
        case 'StartEvent':
          //  this.disableAllBetting(false);
          this.getStats();
          this.getResults();
          if (this.isStandAlone && !this.isGravityGateway) {
            this.getUserBalance();
          }
          break;
        case 'odds':
          setTimeout(() => {
            this.setPayinDisabledFromSocket(false);
          }, 1100);
          if (this.selectedBet) {
            setTimeout(() => {
              this.setIsPayinButtonDisabled(false);
            }, 2400);
          }
          setTimeout(() => {
            store.state.odds = event.detail.odds;
            this.disableAllBetting(false);
          }, 1200);
          break;
        case 'User.AuthorizationChanged':
          // eslint-disable-next-line no-case-declarations
          const authData = event.detail.data.auth;
          if (authData) {
            const playerId = authData.user?.id || null;
            this.togglePlayerChannel(playerId);
            this.authUser(authData);
            this.setUserBalance(authData.user.balance);
          }
          if (this.isStandAlone && this.isGravityGateway) {
            this.getUserBalance();
          }
          if (!this.isUserLoggedIn) {
            if (this.lastSelectedBet) {
              this.setLastSelectedBet(false);
              this.clearBetslip();
              this.resetBalls();
              eventBus.$emit('ClearSpecialBets');
            }
            eventBus.$emit('closeTicketHistory');
          }
          break;
        case 'User.ProfileChanged':
          this.setUserBalance(event.detail.data.auth.user.balance);
          break;
        case 'User.BalanceChanged':
          this.setUserBalance(event.detail.data.balance);
          break;
        case 'TicketUpdate': {
          const msg = {
            message: this.translations.general_ticket_confirmed,
            status: 'positive',
            notificationTimeout: true,
          };
          if (this.isUserLoggedIn) {
            if (event.detail.id && event.detail.status.id === '0') {
              (async () => {
                await this.clearBetslip();
                store.dispatch('notifications/clearNotification').then(() => {
                  store.dispatch('notifications/setNotification', msg);
                });
                each(store.state.balls, (ball) => {
                  // eslint-disable-next-line
                  ball.tabs = [];
                  if (ball.style) {
                    // eslint-disable-next-line
                    ball.style.active = false;
                  }
                });
                this.resetBalls();
                // eslint-disable-next-line no-case-declarations
                const ticketsResolved = {
                  action: 'Tickets.Resolved',
                  data: {
                    accepted: true,
                    ticket: event.detail,
                  },
                };
                this.busService.sendMessage(ticketsResolved, true);
              })();
              setTimeout(() => {
                store.dispatch('notifications/clearNotification');
              }, 1000);
              this.setLastSelectedBet(false);
              this.setIsPayinInProgress(false);
              this.setTicketPayinInProg(false);
              eventBus.$emit('ClearSpecialBets');
              eventBus.$emit('PayinDone');
            }
            if (event.detail.id && event.detail.status.id === '1') {
              msg.message = this.translations.general_ticket_canceled;
              msg.status = 'neutral';
              this.setCanceledTicket(event.detail);
            }
            if (event.detail.code) {
              msg.message = event.detail.message;
              msg.status = 'negative';
              (async () => {
                await this.clearBetslip();
                store.dispatch('notifications/clearNotification').then(() => {
                  store.dispatch('notifications/setNotification', msg);
                });
              })();
              this.resetBalls();
              this.setIsPayinInProgress(false);
              this.setTicketPayinInProg(false);
              eventBus.$emit('ClearSpecialBets');
            }
            if (this.isStandAlone && !this.isGravityGateway) {
              this.getUserBalance();
            }
            this.disableAllBetting(false);
            this.getLastTickets();
          }
          break;
        }
        default:
          break;
      }
    });
    eventBus.$on('ReturnToLobby', () => {
      this.busService.sendMessage(
        {
          action: 'UI.Show',
          data: {
            name: 'Lobby',
          },
        },
        true,
      );
      if (window.WebAppListener) {
        window.WebAppListener.sendMessage('Navigation.Back');
      }
    });
    eventBus.$on('PayinDone', () => {
      this.resetBalls();
    });
    eventBus.$on('TicketPayin', () => {
      //  this.setIsPayinButtonDisabled(true);
      this.setIsPayinInProgress(true);
      this.setTicketPayinInProg(true);
      this.disableAllBetting(true);
      store
        .dispatch('gamesBetslip/payIn', {
          payload: this.formatTicketBets,
          additionalInfo: this.isIsonis
            ? {
                printTemplate: this.getPrintTemplate(),
                timezone: {
                  offset: parseInt(this.userProfile.profile.profile.timezoneOffset, 10),
                },
              }
            : null,
        })
        .then((response) => {
          this.checkTicket(response.data.requestUuid);
          const message = {
            message: this.translations.general_ticket_resolving,
            status: 'neutral',
          };
          store.dispatch('notifications/setNotification', message);
        })
        .catch((error) => {
          if (error.response) {
            const errorData = error.response.data;
            const message = {
              message: errorData.message,
              status: 'negative',
            };
            store.dispatch('notifications/setNotification', message);
          }
          this.setIsPayinInProgress(false);
          this.setTicketPayinInProg(false);
          return error;
        });
    });
    eventBus.$on('PrebetCleared', () => {
      this.setLastSelectedBet(false);
      this.resetBalls();
    });
    eventBus.$on('LoadLastTickets', () => {
      this.getLastTickets();
    });
    eventBus.$on('ReturnToLobby', () => {
      this.busService.sendMessage(
        {
          action: 'UI.Show',
          data: {
            name: 'Lobby',
          },
        },
        true,
      );
      if (window.WebAppListener) {
        window.WebAppListener.sendMessage('Navigation.Back');
      }
    });
    eventBus.$on('LoginRequired', () => {
      if (window.WebAppListener) {
        window.WebAppListener.sendMessage('User.LoginRequired');
      }
      this.busService.sendMessage(
        {
          action: 'User.LoginRequired',
        },
        true,
      );
    });

    if (this.config.platformConfig.clientType === 'token') {
      if (this.userProfile && this.userProfile?.profile?.id) {
        this.togglePlayerChannel(this.userProfile.profile.id);
        this.getLastTickets();
      }
    }
    if (this.config.platformName !== 'seven') {
      this.thirdPartyPlatformSubscribe();
    }
  },
  methods: {
    ...mapActions([
      'getStats',
      'getResults',
      'setUserData',
      'setUserBalance',
      'setUserUuid',
      'setActiveBettingTab',
      'resetColors',
      'resetBalls',
      'setDrawnColor',
      'updateRound',
      'addBettingTab',
      'removeBettingTab',
      'notifications/clearNotification',
      'notifications/setNotification',
      'bettingDisabledEvent',
      'disableAllBetting',
      'setShowTicketCanceledModal',
      'setCanceledTicket',
      'setPayinDisabledFromSocket',
      'setLastSelectedBet',
      'setHideLoader',
      'setDrawnBallsStateActive',
      'setTicketPayinInProg',
    ]),
    ...mapActions('user', ['updateUser']),
    ...mapActions('gamesBetslip', [
      'updateTicket',
      'setIsPayinButtonDisabled',
      'getLastTickets',
      'setIsPayinInProgress',
      'clearBetslip',
      'updateSelectedBet',
      'updateBet',
      'addPreBet',
    ]),
    ...mapActions({
      getUserBalance: 'user/getUserBalance',
    }),
    load() {
      if (this.isAndroidPlatform) {
        window.WebAppListener.sendMessage('Slave.Loaded');
      }
      this.busService.sendMessage({ action: 'Slave.Loaded' }, true);
    },
    setMaxBallsInNextSix(bets) {
      if (bets[7].value && bets[6].value && bets[5].value) {
        this.setInNextSixMaxBall(3);
      }
      if (!bets[7].value && bets[6].value && bets[5].value) {
        this.setInNextSixMaxBall(2);
      }
      if (!bets[7].value && !bets[6].value && bets[5].value) {
        this.setInNextSixMaxBall(1);
      }
    },
    setMaxBallsNextBall(bets) {
      if (bets[3].value && bets[0].value) {
        this.setNextBallMaxBall(2);
      }
      if (!bets[3].value && bets[0].value) {
        this.setNextBallMaxBall(1);
      }
    },
    clearCheckTimeout() {
      clearTimeout(this.check);
    },
    setInNextSixMaxBall(balls) {
      store.state.bettingTabs[0].maxBalls = balls;
    },
    setNextBallMaxBall(balls) {
      store.state.bettingTabs[1].maxBalls = balls;
    },
    checkTicket(requestUuid) {
      this.check = setTimeout(() => {
        store.dispatch('betslip/ticketCheck', requestUuid).then((response) => {
          if (response.data.status.value.toUpperCase() !== 'PENDING') {
            this.getLastTickets();
            this.setIsPayinInProgress(false);
          }
          this.clearCheckTimeout();
        });
      }, this.checkTimeout);
    },
    setTheme(config) {
      const rootElement = document.documentElement;
      const bodyElement = document.body;
      const baseColor = config.ui[config.ui.config.baseColor];
      const theme = config.ui[config.ui.config.theme];
      applyPalette.set(baseColor, rootElement);
      applyPalette.set(theme, bodyElement);
    },
    authUser(auth) {
      store.dispatch(
        'user/updateUser',
        {
          auth: {
            token: auth.token,
            tpToken: auth.tpToken,
          },
          profile: auth.user || auth,
        },
        { root: true },
      );
      this.isLoggedIn = !!auth?.user?.logged || auth.id;
      if (this.isLoggedIn) {
        this.togglePlayerChannel(this.userProfile.profile.id);
      }
    },
    reset() {
      store.state.drawnBalls = [];
      store.state.lessThanSixBallsLeft = false;
      store.state.remainingBalls = [];

      for (let j = 0; j < store.state.bettingTabs.length; j += 1) {
        store.state.bettingTabs[j].active = j === 0;
        store.state.bettingTabs[j].disabled = false;
      }
      this.setActiveBettingTab(0);
      for (let i = 0; i < store.state.balls.length; i += 1) {
        if (store.state.balls[i].tabs) {
          store.state.balls[i].tabs = [];
        }
        if (store.state.balls[i].style) {
          store.state.balls[i].style.disabled = false;
          store.state.balls[i].style.active = false;
        }
      }
      this.resetColors();
      for (let i = 1; i < 49; i += 1) {
        store.state.remainingBalls.push(i);
      }
    },
    updateRemainingBalls(ball) {
      const index = store.state.remainingBalls.indexOf(ball);
      if (index) {
        store.state.remainingBalls.splice(index, 1);
      }
    },
    drawnBallsCheck(balls) {
      if (balls?.length >= 30) {
        store.state.lessThanSixBallsLeft = true;
      }
    },
    getPrintTemplate() {
      let template = '';
      const messages = this.translations;
      const { currency } = this.config;
      const bets = this.ticket.length ? this.ticket : [this.selectedBet];
      template += 'Next Six;';
      template += "{{ ticketDateTimeUTC | date('d.m.Y H:i:s', false) }};";
      template += `${messages.general_pay_in} {{ payin | number_format(2, '.', ',') }}${currency} `;
      template += `${messages.general_payout} {{ payout | number_format(2, '.', ',') }}${currency} `;

      return (
        template +
        bets
          .map((bet) => {
            const betOutcome = isArray(bet.outcome) ? bet.outcome.join(',') : bet.outcome;
            const betMarket = !bet.color
              ? `${bet.market} ${bet.category ? bet.category : ''}`
              : bet.category;
            let body = '';
            let round = '';

            for (let i = 0; i < bet.numEvents; i += 1) {
              round = `${messages.general_round} : {{bets[0].eventId + ${i}}}`;
              body += `${round} `;
              body += `${betOutcome} `;
              body += `${betMarket} `;
            }
            return body;
          })
          .join('; ') +
        '{{ id }}'
      );
    },
    getNumbers(bet) {
      if (bet.numbers.length === 0) {
        return bet.value;
      }
      if (bet.numbers.length === 1) {
        return bet.numbers[0];
      }
      return bet.numbers;
    },
    formatTicketBets() {
      const tickets = this.ticket.length ? this.ticket : [this.selectedBet];
      const result = tickets.map((n) => ({
        payin: n.payment || Number(this.totalPayment),
        market: 'Standard',
        type: n.betType,
        ballId: this.drawnBalls.length + 1,
        ballPositions: n.ballPositions,
        value: this.getNumbers(n),
        numEvents: 1,
      }));
      return result;
    },
    togglePlayerChannel(playerId) {
      if (playerId) {
        this.busService.addChannel(`${this.config.productName}.Player`, playerId);
      } else {
        this.busService.removeChannel(
          `${this.config.productName}.Player`,
          this.userProfile.profile.id,
        );
      }
    },
    setupGtag(device) {
      if (this.config.ui.config.gtm && this.config.ui.config.gtm[device]) {
        const { code, auth, preview } = this.config.ui.config.gtm[device];
        gtag.setup(code, auth, preview);
        return;
      }
      gtag.setup();
    },
    setPageTitle() {
      const { productFqn, applicationName } = this.config;
      document.title = `${startCase(productFqn)}: ${applicationName}`;
    },
    thirdPartyPlatformSubscribe() {
      if (this.config.user && this.config.user.id) {
        const queryParams = this.$route.query;
        this.authUser(this.config.user);
        this.getLastTickets();
        this.setUserBalance(this.config.user.balance);
        this.busService.addChannel('DeliveryPlatform', this.config.platformConfig.clientPlatform);
        if (queryParams.casino) {
          this.busService.addChannel(
            'Balance',
            `${queryParams.casino}-${this.config.user.username}`,
          );
        }
      }
    },
  },
};
</script>

<style lang="scss">
@use './assets/style/helpers/_modal.scss';

* {
  margin: 0;
  padding: 0;
  outline-style: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  scrollbar-width: none;
}
html,
body {
  background-color: var(--background-1);
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: Roboto;
  -webkit-tap-highlight-color: transparent;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app {
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
  width: 100%;
  overflow-y: scroll;
  &.full-web {
    height: 100vh;
  }
}
</style>
